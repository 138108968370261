import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  localLink= "http://localhost:4200/#/"

  siteLink:any;
  warehouseApi:any;
  orderApi:any;
  order2Api:any;
  productApi :any;
  systemApi :any;
  userApi: any;

  baseUrl = ''; // environment.apiRoot;
  baseUrlLidt = ''; // environment.apiRoot;
  baseUrlCr = ''; // environment.apiRoot;
  // baseUrlUP = environment.apiRoot;
  // baseUrl = '';//environment.apiRoot;S

  public loading = false;
  public loadingMessage = 'Loading...';
  constructor(private http: HttpClient, private router: Router, private _route: ActivatedRoute) {

    if (window.location.href.includes('rr.com')) {
      this.siteLink= "https://rr.com/#/"
      this.warehouseApi = 'https://warehouse2.rr.com/public/';
      this.orderApi = 'https://order.rr.com/public/';
      this.order2Api = 'https://order2.rr.com/public/';
      this.productApi = 'https://products.rr.com/public/';
      this.systemApi = 'https://system.rr.com/public/';
      this.userApi = 'https://user.rr.com/public/';
    } else {
      this.siteLink= "";
      this.warehouseApi = "https://rizman.warehouse.iwh2u.com/";
      this.orderApi = "https://rizman.order.iwh2u.com/";
      this.order2Api = "";
      this.productApi = "https://rizman.product.iwh2u.com/";
      this.systemApi = "https://rizman.system.iwh2u.com/";
      this.userApi = 'https://rizman.user.iwh2u.com/';
    }
  }

  // GET
  public get(controller) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller);
  }
  public read(controller, id) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller + '/' + id);
  }
  // CREATE
  create(controller, model) {
    return this.post(controller, model);
  }

  // Post
  postSimple(controller) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, {});
  }
  // Post
  post(controller, model) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, model);
  }

  public getAsync(controller): Observable<any> {
    this.loading = true;
    return this.http.get<any>(this.baseUrl + controller).pipe(tap((_) => console.log('Loaded')));
  }

  // list
  public list(listName) {
    this.loading = true;
    return this.http.get(this.baseUrl + 'list/' + listName);
  }

  // READ
  public getWithId(controller, model) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller + '/' + model.id);
  }

  // Update
  public update(controller, model) {
    this.loading = true;
    return this.http.put(this.baseUrl + controller + '/' + model.id, model);
  }

  // Delete
  public delete(controller, model) {
    this.loading = true;
    return this.http.delete(this.baseUrl + controller + '/' + model.id);
  }
}
