import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../@services/storage.service';
import { AuthService } from './../@services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private storage: StorageService) { }
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   if (!this.authService.loggedIn()) {
  //     this.router.navigate(["/sign-in"])
  //   }
  //   return true;
  // }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.loggedIn()) {
      this.router.navigate(["/sign-in"])
    }else{

      this.checkUserAuth(next)
    }

    if(this.checkUserAuth(next) == false && !(window.location.href.includes('sign-in'))){
    return false;
    }else{
      return true;
    }
  }


  checkUserAuth(route: ActivatedRouteSnapshot) {
    let user = this.storage.get('auth.user')
      if (route.data.role && route.data.role.indexOf(user.user_role) == -1) {
        return false;
      }
  }
}
