import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  token: string = null
  constructor(private api: ApiService,
    private spinner: NgxSpinnerService,
    private storage: StorageService, private router: Router) { }

  register(type, value) {
    const user = value;
    return new Promise<any>((resolve, reject) => {
      this.api.post("/" + type + "/register", user).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  login(values:any) {
    const user = {
      email: values.email, password: values.password
    };
    return new Promise<any>((resolve, reject) => {
      this.api.post(this.api.userApi + "admin-login", user).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
          this.spinner.hide();
        }
      );
    });
  }
  loggedIn() {
    const token = this.storage.get("auth.accesstoken");
    if (token) {
      // return true;
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }
  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }
}
